html,
body {
  margin: 0;
  height: 100%;
  background-color: #f2f3f7;
}

body.previewPage {
  background-color: #fff;
  min-height: 100%;
  overflow: auto;
}

#root {
  height: 100%;
}
