#root .ql-container {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  flex: 20;
}

#root .ql-toolbar.ql-snow {
  border: 0;
}

#root .ql-container.ql-snow {
  border: 1px solid #ededed;
  border-radius: 8px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: 90%;
}

#root .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ededed;
}

#root .quill {
  position: relative;
}

#root .ql-tooltip {
  left: 10px !important;
}
