.sectionName {
  font-family: "Helvetica", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  color: #000000;
  margin: 16px;
  padding-bottom: 16px;
  display: flex;
  border-bottom: 1px solid;
  border-color: #d9d9d9;
}

.titleContainer {
  margin: 16px;
  margin-bottom: 40px;
}

.titleContainer p {
  font-family: Arial, Helvetica, sans-serif;
}

.title {
  font-family: "Helvetica", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  color: #000000;
  margin: 16px 0px 40px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 780px) {
  .title {
    font-size: 36px;
  }
}

.subtitle {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  color: #000000;
  margin: 16px;
  padding-bottom: 16px;
  display: flex;
  border-bottom: 1px solid;
  border-color: #d9d9d9;
}

@media (min-width: 780px) {
  .subtitle {
    font-size: 28px;
  }
}

.sponsor {
  justify-content: center;
  min-width: 350px;
  height: auto;
  background-color: #d9d9d9;
  display: flex;
}
.sponsor p {
  font-family: sans-serif;
}

.sponsor .desktop {
  display: none;
}

.sponsor .mobile {
  display: flex;
  min-height: 100px;
  align-items: center;
}

@media (min-width: 780px) {
  .sponsor .mobile {
    display: none;
  }

  .sponsor .desktop {
    display: flex;
    flex-direction: column;
  }
}

.body {
  font-family: "Helvetica", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  color: #000000;
  display: flex;
  flex-direction: column;
  margin-inline: 16px;
  font-size: 20px;
  border-color: #d9d9d9;
}

@media (min-width: 780px) {
  .body {
    font-size: 17px;
    width: auto;
  }
}

.body .bigBody {
  border-bottom: 1px solid;
  border-color: #d9d9d9;
  font-size: 15px;
}

.block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  position: relative;
}

@media (min-width: 780px) {
  .block {
    width: 80%;
  }
}

.bodyWithSponsor {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 780px) {
  .bodyWithSponsor {
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
  }
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 780px) {
  img {
    width: 100%;
    height: 100%;
  }
}

iframe {
  width: 100%;
  height: 480px;
}

iframe,
img {
  pointer-events: none;
}

@media (max-width: 780px) {
  iframe {
    width: 100%;
    height: auto;
    min-height: 240px;
  }
}

.image_container {
  position: relative;
  justify-content: center;
  display: flex;
}

@media (min-width: 780px) {
  .image_container {
    height: 507px;
  }
}

.image_container div {
  position: unset !important;
}

.image_container .image {
  object-fit: contain;
  width: 100%;
  position: relative !important;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 780px) {
  .image_container .image {
    width: auto;
    height: 100%;
  }
}

.image_container .logo {
  position: absolute;
  width: auto;
  bottom: -2em;
  left: 50%;
  transform: translateX(-50%);
}

.date {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.date p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 110%;
}

@media (min-width: 780px) {
  .date p {
    font-size: 16px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  margin: 16px;
  margin-bottom: 28px;
}

@media (min-width: 780px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.gridItem {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.dividerVerticalDesktop {
  width: 1px;
  height: 100%;
  margin: 0px 5px;
  background-color: #ccc;
}

@media (max-width: 780px) {
  .dividerVerticalDesktop {
    display: none;
  }

  .dividerVerticalMobile {
    width: 1px;
    height: 100%;
    margin: 0px 5px;
    background-color: #ccc;
  }
}

.mockBlock {
  width: 100%;
  /* max-width: 208px; */
  height: 200px;
  background-color: #d9d9d9;

  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.mockLabel {
  text-align: center;
}
